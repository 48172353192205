var scrollAnimeArray = [],
	ArrayCount = 0,
	animationFlag = false,
	$activeClass = 'is-active',
	$inactiveClass = 'is-inactive',
	$openClass = 'is-open',
	$shortClass = 'is-short',
	$animeClass = 'is-anime',
	$currentClass = 'is-current',
	returnScroll, timeout, pointer,
	evaluationFlag = false,
	pcMinWidth = 768,
	currentWidth = $(window).innerWidth(),
	ovarlayFlag = false;

document.addEventListener('DOMContentLoaded', function() {
	// cookie処理
	splashDisappear();
});

/*
* レイジーロード
* 1) imgタグに.js-lazyload付与時、画像をレイジーロードする。
*/
$('img.js-lazyload').lazyload();

/*
* ページイン時
* 1) URLにハッシュが入っている場合、自動でのスクロールを停止する。
*	2) Android端末の場合 bodyにクラス「android」を付与する。
*/
$(document).ready(function() {
	$('body,html').stop().scrollTop(0);

	if (navigator.userAgent.indexOf('Android') > 0) {
		$('body').addClass('android');
	}
});

/*
* ロード時
* 1) 画面幅540以下の時、トップのコンテンツに横幅制御の処理を実施
* 2) カルーセル存在時、初期表示調整。(3)より先に設定必須。
* 3) js-scroll_animationが存在する場合、IDを連番で自動付番。それを配列化。
* 	既に表示されているものについては、is-animeクラスを付与。
* 4) 「ご意見をお聞かせください」エリアの高さを指定。
* 5) ページイン時のハッシュタグの位置に向けて移動。
*/
$(window).on('load', function() {
	// ファビコン
	ins_favicon('/material/img/common/favicon.ico', 'shortcut icon');
	ins_favicon('/material/img/common/apple-touch-icon-precomposed.png', 'apple-touch-icon-precomposed');

	if (document.getElementById('js-app_index')) {
		if ($(window).innerWidth() < 540) {
			$('.js-top_contents_item').addClass('is-hide').css('max-width', $(window).innerWidth() - 80);
		}
	}

	if (window.innerWidth <= pcMinWidth) {
		if ($(window).scrollTop() > 10) {
			$('#js-main').css('padding-top', $('#js-header_sp').outerHeight() + 55);
		} else {
			$('#js-main').css('padding-top', $('#js-header_sp').outerHeight());
		}
	} else if ($(window).scrollTop() > 10) {
		$('#js-main').css('padding-top', $('#js-header').outerHeight() + 26);
	} else {
		$('#js-main').css('padding-top', $('#js-header').outerHeight());
	}

	//カルーセルの初期表示
	slickInitialize();
	if ($('.js-flow').length) {
		$('.js-flow_slider_head').each(function() {
			var _slickHeadHeight = $(this).outerHeight(),
				_slickHeadStep = $(this).find('.flow-slider__head-step');

			_slickHeadStep.css('height', _slickHeadHeight);
		});

		$('.js-flow').each(function() {
			var _slickTrack = $(this).find('.slick-track'),
				_slickHeight = _slickTrack.outerHeight();

			_slickTrack.css('height', _slickHeight);
		});
	}

	if ($('.js-flow')[0]) {
		slickUnset();
	}

	// スクロールイン時のアニメーション処理
	if (document.getElementsByClassName('js-scroll_animation')) {
		$('.js-scroll_animation').each(function(i) {
			$(this).attr('id', 'js-scroll_anime_' + (i + 1));
		});

		for (let i = 0; i < $('.js-scroll_animation').length; i++) {
			if ($('.js-scroll_animation')[i].getBoundingClientRect().top < window.innerHeight) {
				let target = '#js-scroll_anime_' + (i + 1);
				$(target).addClass($animeClass);
			} else {
				scrollAnimeArray.push([Math.round($('.js-scroll_animation')[i].getBoundingClientRect().top) + 10, i]);
				ArrayCount++;
			}
		}

		scrollAnimeArray.sort(arraySort);
	}

	// 「ご意見をお聞かせください」エリアの高さ指定
	if (document.getElementById('js-evaluation')) {
		if (evaluationFlag) {
			$('#js-evaluation').css('height', $('#js-evaluation_thank').outerHeight());
		} else {
			$('#js-evaluation').css('height', $('#js-evaluation_inner').outerHeight() + 60);
		}
	}

	// フローティングDLボタンがない場合のチャットボット位置調整
	if (!$('.under-display')) {
		$('#bedore-webagent-inner').addClass('under-display-close');
	} else {
		$('.totop').addClass('under-display-open');
		$('.footer').addClass('under-display-open');
	}
	setTimeout(() => {
		$('#bedore-webagent-inner').contents().on('click', function() {
			const chatboxHeight = $('#bedore-webagent-inner').attr('height');
			const num = Number(chatboxHeight.substr(0, chatboxHeight.indexOf('px')));
			if (num > 61) {
				$('.totop').css('z-index', '998');
			} else {
				$('.totop').css('z-index', '10001');
			}
		});
	}, 900);
	// ページイン時のハッシュタグの位置に向けて移動
	if (location.hash !== '') {
		setTimeout(function() {
			smoothScroll(String(location.hash));
		}, 1);
	}

	if (location.search !== '') {
		var scroolTarget = location.search.split(/\?/)[1].split(/_/)[0];
		var scroolTargetNum = Number(location.search.split(/\?/)[1].split(/_/)[1]) - 1;
		setTimeout(function() {
			smoothScroll(String('#' + scroolTarget));
			var target = $('#' + scroolTarget)[0];
			setTimeout(() => {
				$(target).find('.slick-slider').slick('slickGoTo', scroolTargetNum, false);
			}, 900);
		}, 1);
	}

	//SPのみ電話発信を可能にする
	var deviceType = window.navigator.userAgent.toLowerCase();
	if (deviceType.indexOf('android') > 0 || deviceType.indexOf('iphone') > 0) {
		$('.js-call_text').each(function() {
			$(this).removeClass('is-inactive');
		});
	}

	//タブの初期表示
	if ($('.js-tab')[0]) {
		// 初期化
		var DefaultTargetId = $('.js-tab_item a[href^="#"]').filter('.' + $activeClass).attr('href').replace('#', '');
		tabOpen(DefaultTargetId);
		if (location.hash.replace('#', '').slice(0, 3) === 'tab') {
			var HashTargetId = String(location.hash).replace('#', '');
			tabOpen(HashTargetId);
			smoothScroll(String(location.hash));
		}
		$('.js-flow_slider_head').each(function() {
			var _slickHeadHeight = $(this).find('.flow-slider__head-inner').outerHeight(),
				_slickHeadStep = $(this).find('.flow-slider__head-step');

			_slickHeadStep.css('height', _slickHeadHeight);
		});
	}

	let target,
		targetId,
		targetSpId,
		targetChild,
		targetCildSpId;

	if (location.pathname.split('/')[2]) {
		target = location.pathname.split('/')[2];
		targetId = '#js-current_' + target;
		targetSpId = '#js-current_' + target + '_sp';
		$(targetId).addClass($currentClass);
		$(targetSpId).addClass($currentClass);
		hierarchyAccordion($(targetSpId));
	}

	if (location.pathname.split('/')[3]) {
		targetChild = location.pathname.split('/')[3].split('.')[0];
		targetCildSpId = '#js-current_' + target + '_' + targetChild + '_sp';
		if ($(window).innerWidth() <= pcMinWidth) {
			$(targetCildSpId).addClass($currentClass);
		}
	}
});


/*
* リサイズ時
* 1) 画面幅540以下の時、トップのコンテンツに横幅制御の処理を実施。以上の時は横幅制御を除外
* 2) PCレイアウトである時、10px以上スクロールをしていればヘッダーをショートサイズにする。
*/
$(window).on('resize', function() {
	if (currentWidth === $(window).innerWidth()) {
		return;
	}
	currentWidth = $(window).innerWidth();
	if ($(window).innerWidth() < 540) {
		$('.js-top_contents_item').addClass('is-hide').css('max-width', $(window).innerWidth() - 80);
	} else {
		$('.js-top_contents_item').removeClass('is-hide');
	}
	if ($(window).innerWidth() > pcMinWidth) {
		if ($(window).scrollTop() > 10) {
			shortHeader();
		} else {
			inShortHeader();
		}
	} else {
		$('#js-main').css('padding-top', 0);
		inShortHeader();
	}

	if (document.getElementById('js-evaluation')) {
		if (evaluationFlag) {
			$('#js-evaluation').css('height', $('#js-evaluation_thank').outerHeight());
		} else {
			$('#js-evaluation').css('height', $('#js-evaluation_inner').outerHeight() + 60);
		}
	}
	if (window.innerWidth <= pcMinWidth) {
		$('#js-main').css('padding-top', $('#js-header_sp').outerHeight());
	} else if (window.innerWidth > pcMinWidth && $('#js-header_wrap').hasClass($shortClass)) {
		$('#js-main').css('padding-top', $('#js-header').outerHeight() + 26);
	} else {
		$('#js-main').css('padding-top', $('#js-header').outerHeight());
	}

	if ($('.js-flow').length) {
		$('.js-flow_slider_head').each(function() {
			var _slickHeadHeight = $(this).find('.flow-slider__head-inner').outerHeight(),
				_slickHeadStep = $(this).find('.flow-slider__head-step');

			_slickHeadStep.css('height', _slickHeadHeight);
		});
	}
});


/*
* スクロール時
* 1) PCレイアウトである時、10px以上スクロールをしていればヘッダーをショートサイズにする。
* 2) メインコンテンツに対して、ヘッダーの高さ分の上余白を付与。
* 3) トップページ以外の時、スクロールインのアニメーションを発火させる。
*/
$(window).on('scroll', function() {
	if ($(window).scrollTop() > 10 && !$('#js-menu').find('.header-menu__btn').hasClass($activeClass)) {
		shortHeader();
	} else {
		inShortHeader();
	}
	if (ArrayCount > 0) {
		if ($(window).scrollTop() + $(window).innerHeight() > scrollAnimeArray[0][0] + 50) {
			let target = '#js-scroll_anime_' + (scrollAnimeArray[0][1] + 1);
			$(target).addClass($animeClass);
			animationFlag = true;
			scrollAnimeArray.shift(0);
			ArrayCount--;
		}
	}
});

$('#js-evaluation').on('change', () => {
	$('#evaluation-submit').removeClass('is-inactive');
});

/*
* 1) スクロールインのアニメーション動作が重ならないようタイミング制御
*/
$('#js-menu').on('click', function() {
	var _targetHeight = $('#js-header_menu').find('.header-menu__inner').outerHeight(),
		headerHeight = $('#js-header_sp').outerHeight(),
		scrollTop = $(window).scrollTop(),
		windowWidth = window.innerWidth,
		mainScroll = headerHeight - scrollTop,
		$btnmenu = $(this).find('.header-menu__btn'),
		mainAddClass = {
			top: mainScroll,
			width: windowWidth
		};

	if (!$btnmenu.hasClass($activeClass)) {
		$('#js-overlay').addClass($activeClass);
		$('#js-main').addClass($inactiveClass).css(mainAddClass);
		$('#js-header_menu_btn_lead').text('閉じる');
		returnScroll = scrollTop;
		if (scrollTop === 0) {
			$btnmenu.addClass($activeClass);
			$('#js-header_menu').addClass($activeClass).css({
				'height': _targetHeight,
				'top': headerHeight
			});
			$('#js-header_sp').addClass($activeClass);
			$('#js-main').css('padding-top', 0);
		} else {
			$btnmenu.addClass($activeClass);
			if ($(window).innerWidth() > pcMinWidth) {
				$('#js-header_menu').addClass($activeClass).css('top', headerHeight - 10);
			} else {
				$('#js-header_menu').addClass($activeClass).css('top', 115);
			}
			$('#js-menu').addClass($activeClass).css('top', 0);
		}
		if ($('#js-header_search').hasClass($activeClass)) {
			$('#js-header_search').removeClass($activeClass);
			setTimeout(function() {
				$('#js-search').removeClass($activeClass);
				$('#js-search_close').removeClass($activeClass);
			}, 100);
		}
	} else {
		removeHeaderMenu();
		$(window).scrollTop(returnScroll);
		$('#js-header_menu_btn_lead').text('メニュー');
		$('#js-main').css({
			'width': 'auto',
			'top': 'auto',
			'padding-top': headerHeight
		});
	}

	$('#js-header_search_btn_lead').text('検索');
});

//ハンバーガーメニューの非表示
$('#js-menu_close').on('click', function() {
	var headerHeight = $('#js-header_sp').outerHeight();
	removeHeaderMenu();
	$(window).scrollTop(returnScroll);
	$('#js-header_menu_btn_lead').text('メニュー');
	$('#js-main').css({
		'width': 'auto',
		'top': 'auto',
		'padding-top': headerHeight
	});
});

/* ----------------
	pcHeader
	----------------- */
//メガドロップメニュー表示
$('.js-header_navi').on('click', function() {
	pointer = '#' + $(this).data('nav');
	dropMenu(pointer);
});

//メガドロップメニュー非表示
$(document).on('click', function(e) {
	var $target = $(e.target);

	if ($target.closest('.js-header_navi').length === 0 && $target.closest('.js-header_drop_menu').length === 0) {
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			$(pointer).removeClass($activeClass);
			$(pointer).hide();
		}, 0);
	} else {
		clearTimeout(timeout);
	}
});

/* ----------------
	accordion
	階層構造アコーディオン
	----------------- */
$('.js-list_accordion').on('click', function() {
	var $this = $(this);
	hierarchyAccordion($this);
});

/* ----------------
	overlay
	overlayクリック時にactive解除
	----------------- */
$('#js-overlay').on('click', function() {
	var headerHeight = $('#js-header_sp').outerHeight();
	$('#js-header_search_btn_lead').text('検索');
	$('#js-menu_lead').text('メニュー');
	$('#js-overlay').removeClass($activeClass);

	if ($('#js-header_menu').hasClass($activeClass)) {
		$('#js-main').css('padding-top', headerHeight);
		removeHeaderMenu();
	} else if ($('#js-header_search').hasClass($activeClass)) {
		$('#js-main').css('padding-top', 0);
		removeHeaderSearch(headerHeight);
	}
});

//グループメニューの表示
$('#js-search').on('click', function() {
	$('#js-header_menu_btn_lead').text('メニュー');
	var headerHeight = ($('#js-header_sp').outerHeight());
	if ($('#js-header_menu').hasClass($activeClass)) {
		removeHeaderMenu();
	}
	if ($('#js-search').hasClass($activeClass)) {
		$('#js-main').css('padding-top', headerHeight);
		removeHeaderSearch();
	} else {
		$('#js-main').css('padding-top', 0);
		fixedHeaderSearch(headerHeight);
	}
});

/* ----------------
	accordion
	シンプルアコーディオン
----------------- */
$('.js-accordion').on('click', function() {
	var $targetParent = $(this).parent();
	simpleAccordion($targetParent);
});

$('#evaluation-submit').on('click', function() {
	$('#js-evaluation').addClass('is-submit');
	$('#js-evaluation').css('height', $('#js-evaluation_thank').outerHeight());
	evaluationFlag = true;
});

/* ----------------
	tab
	ページ内タブ切り替え
	----------------- */

//アンカーで移動してきてtabを開く場合
$('.js-tab_item a[href^="#"]').on('click', function(e) {
	e.preventDefault();
	var targetId = $(this).attr('href').replace('#', ''),
		_headerHeight = $('#js-header').outerHeight(),
		_Position = $('#js-tab_anchor').offset().top;

	//カルーセルがある場合、初期位置にセットし直す
	if ($('.js-flow')[0]) {
		slickUnset();
	}

	tabOpen(targetId);

	if ($('.js-reload')[0]) {
		campaignHeightControl();
	}

	$('.js-flow_slider_head').each(function() {
		var _slickHeadHeight = $(this).find('.flow-slider__head-inner').outerHeight(),
			_slickHeadStep = $(this).find('.flow-slider__head-step');

		_slickHeadStep.css('height', _slickHeadHeight);
	});

	if ($(window).width() > 768) {
		_Position -= _headerHeight;
	}

	//タブ内のトップへ移動
	if ($(window).scrollTop() > 10) {
		_Position -= 30;
	}
	$('html,body').animate({scrollTop: _Position}, 900);
});


/* ----------------
	tab-guide
	タブガイドの表示非表示
	----------------- */
$('.tab-list').on('touchmove', function() {
	guideView('.js-tab_guide');
});
$('.js-tab_item a').on('click', function() {
	guideView('.js-tab_guide');
});

/* ----------------
	フローティングバナー非表示
	閉じるボタンクリック時に非表示
	----------------- */
$('#js-under-display-close').on('click', function() {
	$('.under-display').css('display', 'none');
	$('#bedore-webagent-inner').addClass('under-display-close');
	$('.totop').removeClass('under-display-open');
	$('.footer').removeClass('under-display-open');
});

/* ----------------
	function
	----------------- */

/*
 *dropMenu
 **/
var dropMenu = function(target) {
	if ($('.js-header_drop_menu').hasClass($activeClass) && !$(target).hasClass($activeClass)) {
		$('.js-header_drop_menu').removeClass($activeClass);
		$('.js-header_drop_menu').hide();
	}

	if (!$(target).hasClass($activeClass)) {
		var _headerHeight = $('#js-header').outerHeight();
		var _windowHeight = window.outerHeight;
		var _max_height = _windowHeight - _headerHeight - 100;
		$('.js-header_drop_menu').css('max-height', _max_height);
		$('.js-header_drop_menu .' + $activeClass).hide();
		$('.js-header_drop_menu .' + $activeClass).removeClass($activeClass);
		$(target).addClass($activeClass);
		$(target).fadeIn();
	} else if ($(target).hasClass($activeClass)) {
		$(target).removeClass($activeClass);
		$(target).hide();
	}
};

/* ----------------
	hierarchyAccordion（階層構造のアコーディオン）
	----------------- */
var hierarchyAccordion = function($target) {
	var $targetId = '#' + $target.data('accordion'),
		_targetHeight = $($targetId).find('.hide-target__inner').outerHeight();

	if (!($target).hasClass('js-list_accordion_nest')) {
		if (!$target.hasClass($inactiveClass)) {
			$target.addClass($inactiveClass);
			$($targetId).addClass($inactiveClass).css('height', 0);
		} else {
			$target.removeClass($inactiveClass);
			$($targetId).removeClass($inactiveClass).css('height', _targetHeight);
		}
	} else {
		var $targetParent = $target.parent().parent().parent(),
			_targetParentHeight = $targetParent.find('.hide-target__inner').outerHeight();

		if (!$target.hasClass($inactiveClass)) {
			_targetParentHeight = _targetParentHeight - _targetHeight;
			$target.addClass($inactiveClass);
			$($targetId).addClass($inactiveClass).css('height', 0);
		} else {
			_targetParentHeight = _targetParentHeight + _targetHeight;
			$target.removeClass($inactiveClass);
			$($targetId).removeClass($inactiveClass).css('height', _targetHeight);
		}
		$targetParent.css('height', _targetParentHeight);
	}
};

/* ----------------
	fixedHeaderMenu
	----------------- */
var fixedHeaderMenu = function() {
	$('#js-overlay').addClass($activeClass);
	$('#js-main').addClass($inactiveClass).css(mainAddClass);
	$('#js-menu_lead').text('閉じる');
};

/* ----------------
	removeHeaderMenu
	----------------- */
var removeHeaderMenu = function() {
	$('#js-menu').find('.header-menu__btn').removeClass($activeClass);
	$('#js-header_menu').removeClass($activeClass);
	$('#js-header_sp').removeClass($activeClass).css('top', 0);
	$('#js-overlay').removeClass($activeClass);
	$('#js-menu').removeClass($activeClass);
	$('#js-main').removeClass($inactiveClass);
	$('#js-menu_lead').text('メニュー');
};

/* ----------------
	fixedHeaderSearch
	----------------- */
var fixedHeaderSearch = function(headerHeight) {
	var windowWidth = window.innerWidth,
		scrollTop = $(window).scrollTop(),
		mainScroll = headerHeight - scrollTop,
		mainAddClass = {
			top: mainScroll,
			width: windowWidth
		};

	returnScroll = scrollTop;
	$('#js-search').addClass($activeClass);
	$('#js-header_search').addClass($activeClass);
	$('#js-header_search_btn_lead').text('閉じる');
	$('#js-main').addClass($inactiveClass).css(mainAddClass);
	$('#js-overlay').addClass($activeClass);
	$('#js-header_sp').addClass($activeClass);
};

/* ----------------
	removeHeaderSearch
	----------------- */
var removeHeaderSearch = function(headerHeight) {
	$('#js-search').removeClass($activeClass);
	$('#js-header_search').removeClass($activeClass);
	$('#js-header_search_btn_lead').text('検索');
	$('#js-overlay').removeClass($activeClass);
	$('#js-main').removeClass($inactiveClass).css('padding-top', headerHeight);
	$(window).scrollTop(returnScroll);
	$('#js-main').css({
		'width': 'auto',
		'top': 'auto'
	});
};

/* ----------------
	shortHeader
	----------------- */
var shortHeader = function() {
	$('#main').addClass('is-fixed');
	if ($(window).innerWidth() > pcMinWidth) {
		$('#js-header').addClass($shortClass);
		$('#js-header_wrap').addClass($shortClass);
	} else {
		$('#js-header_sp').addClass($shortClass);
	}
};

var inShortHeader = function() {
	$('#main').removeClass('is-fixed');
	if ($(window).innerWidth() > pcMinWidth) {
		$('#js-header').removeClass($shortClass);
		$('#js-header_wrap').removeClass($shortClass);
	} else {
		$('#js-header_sp').removeClass($shortClass);
	}
};


/* ----------------
	totop
	----------------- */
$(window).on('scroll', function() {
	if ($('#js-totop').hasClass($activeClass)) {
		$('#js-totop').fadeOut().removeClass($activeClass);
	}
	var scrollTop = $(window).scrollTop(),
		scrollHeight = $(document).height(),
		scrollPosition = $(window).height() + $(window).scrollTop(),
		windowWidth = window.innerWidth,
		footerHeight = $('footer').innerHeight(),
		toTopPosition;

	//toTop表示
	clearTimeout(timeout);
	toTopPosition = windowWidth - 10;
	//表示枠の高さ調整、totopの位置調整
	if (scrollHeight - scrollPosition <= footerHeight) {
		$('.totop').css ({
			'bottom': footerHeight + 30,
			'left': toTopPosition,
			'position': 'absolute',
			'margin-bottom': '0',
		});
	} else {
		$('.totop').css ({
			'bottom': '50px',
			'left': toTopPosition,
			'position': 'fixed',
			'margin-bottom': '',
		});
	}
	if (windowWidth > pcMinWidth) {
		toTopPosition = windowWidth - 80;
		if (scrollHeight - scrollPosition <= footerHeight) {
			$('.totop').css ({
				'bottom': footerHeight + 40,
				'left': toTopPosition,
				'position': 'absolute',
				'margin-bottom': '0',
			});
		} else {
			$('.totop').css ({
				'bottom': '50px',
				'left': toTopPosition,
				'position': 'fixed',
				'margin-bottom': '',
			});
		}
	}
	if (!footerHeight && scrollHeight - scrollPosition <= 30) {
		$('.totop').css ({
			'bottom': scrollHeight - scrollPosition + 70,
			'left': toTopPosition,
			'position': 'fixed',
			'margin-bottom': '',
		});
	} else if (!footerHeight) {
		$('.totop').css ({
			'bottom': 100,
			'left': toTopPosition,
			'position': 'fixed',
			'margin-bottom': '',
		});
	}
	timeout = setTimeout(function() {
		if (scrollTop !== 0) {
			$('#js-totop').fadeIn().addClass($activeClass);
		}
	}, 300);
});

$('#js-totop').on('click', function() {
	toTop();
});

var toTop = function() {
	$('html,body').animate({
		scrollTop: 0
	}, 900);
};

/* ----------------
	スムーススクロール
----------------- */
$('.js-anchor').on('click', function(e) {
	e.preventDefault();
	smoothScroll(this.hash);
});

var smoothScroll = function(anchor) {
	var _headerHeight = 0,
		_Position = $(anchor).offset().top;

	if ($(window).width() > pcMinWidth) {
		if ($('#js-header').length) {
			_headerHeight = $('#js-header').outerHeight();
		}
		_Position -= _headerHeight + 30;
		if ($(window).scrollTop() > 10) {
			_Position -= 34;
		}
	} else {
		if ($('#js-header').length) {
			_headerHeight = 63;
		}
		_Position -= _headerHeight + 30;
	}

	$('html,body').animate({scrollTop: _Position}, 900);
};


/* ----------------
	slickInitialize
----------------- */
var slickInitialize = function() {
	var $flow = $('.js-flow');
	$flow.each(function() {
		var $this = $(this);

		if ($this.find('li').length > 3) {
			$this.slick({
				infinite: false,
				dots: true,
				variableWidth: true,
				responsive: [{
					breakpoint: 767,
					settings: {
						dots: true,
						variableWidth: true,
						slidesToShow: 1
					}
				}]
			});
		} else if ($this.find('li').length >= 2 && window.innerWidth <= pcMinWidth) {
			$this.slick({
				infinite: false,
				arrows: false,
				dots: true,
				variableWidth: true,
				draggable: false,
				responsive: [{
					breakpoint: 1080,
					settings: {
						arrows: true,
						draggable: true,
						slidesToShow: $this.length
					}
				}, {
					breakpoint: 767,
					settings: {
						arrows: true,
						draggable: true,
						variableWidth: true,
						slidesToShow: 1
					}
				}]
			});
		} else if ($this.find('li').length >= 2 && window.innerWidth >= pcMinWidth) {
			$this.slick({
				infinite: false,
				arrows: false,
				dots: false,
				variableWidth: true,
				draggable: false,
				responsive: [{
					breakpoint: 1080,
					settings: {
						arrows: true,
						draggable: true,
						slidesToShow: $this.length
					}
				}, {
					breakpoint: 767,
					settings: {
						arrows: true,
						draggable: true,
						variableWidth: true,
						slidesToShow: 1
					}
				}]
			});
		} else {
			$this.find('li').css('margin-left', 0);
		}
	});
};
/* ----------------
	slickUnset
----------------- */
var slickUnset = function() {
	$('.js-flow.slick-initialized').slick('unslick');
	$('.js-flow').hide();
	slickInitialize();
	$('.js-flow').fadeIn();
	setTimeout(function() {
		if ($('.js-flow').length) {
			$('.js-flow').each(function() {
				var _slickTrack = $(this).find('.slick-track'),
					_slickHeight = _slickTrack.outerHeight();

				_slickTrack.css('height', _slickHeight);
			});
		}
	}, 300);
};


/* ----------------
	guideView
----------------- */
var guideView = function(guideClass) {
	setTimeout(function() {
		$(guideClass).fadeOut(300);
	}, 1000);
};
$('.js-flow').on('beforeChange', function() {
	guideView('.js-guide');
});

/* ----------------
	simpleAccordion
----------------- */
var simpleAccordion = function($targetParent) {
	var $accordion = $targetParent.find('.js-accordion'),
		$target = $targetParent.find('.js-hide__target'),
		targetHeight = $targetParent.find('.js-hide_target_inner').innerHeight();

	if ($accordion.hasClass($openClass)) {
		$accordion.removeClass($openClass);
		$target.removeClass($openClass).css('height', 0);
	} else {
		$accordion.addClass($openClass);
		if ($target.hasClass('hide-target--wide')) {
			$target.addClass($openClass).css('height', targetHeight);
		} else {
			$target.addClass($openClass).css('height', targetHeight + 40);
		}
	}
};

/* ----------------
	tabOpen
----------------- */
var tabOpen = function(hashVal) {
	var targetHash  = '#' + hashVal,
		tabKind, $tabLinks, $tabContents;

	if ($(targetHash).hasClass('tab-content')) {
		tabKind = 'tab';
	} else {
		return false;
	}

	$tabLinks = $('.' + tabKind + ' a[href*="#"]');
	$tabContents = (tabKind === 'tab') ? $('.' + tabKind + '-content') : $('.' + tabKind + '-content > *');
	$tabLinks.removeClass($activeClass);
	$('a[href="' + targetHash + '"]').addClass($activeClass);
	$tabContents.not($(targetHash)).removeClass($activeClass);
	$(targetHash).addClass($activeClass);
};

/* ----------------
	campaignHeightControl
----------------- */
var campaignHeightControl = function() {
	var $reloader = $('.js-loader'),
		$reloadRoot = $('.js-reload'),
		$reloadBtn = $('.js-reload__btn');

	if ($(window).width() < 768 && !$reloadRoot.eq(0).hasClass('is-after')) {
		var	$hideItems = $reloadRoot.eq(0).find('.campaign'),
			_hideHeight = Math.ceil($hideItems.eq(0).outerHeight() + $hideItems.eq(1).outerHeight() + parseInt($hideItems.eq(1).css('margin-top')) + $hideItems.eq(2).outerHeight() + parseInt($hideItems.eq(2).css('margin-top')) + $hideItems.eq(3).outerHeight() + parseInt($hideItems.eq(3).css('margin-top')));
		$reloadRoot.eq(0).height(_hideHeight);
	} else {
		$reloadRoot.eq(0).css('height', 'auto');
	}
	$reloadBtn.on('click touchstart', function(e) {
		e.preventDefault();
		var	_showHeight = $reloadRoot.eq(0).find('.js-reload__inner').outerHeight();
		$reloadBtn.eq(0).fadeOut(300);
		$reloader.eq(0).fadeIn(300);
		setTimeout(function() {
			$reloader.eq(0).fadeOut(300);
			$reloadRoot.eq(0).height(_showHeight).addClass('is-after');
			$('.reload-btn__wrap').eq(0).hide();
		}, 1000);
	});
};

var arraySort = function(a, b) {
	return a[0] - b[0];
};

// ファビコン
function ins_favicon(URL, rel) {
	var link = document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = rel;
	link.href = URL;

	document.getElementsByTagName('head')[0].appendChild(link);
}

//------------------------------------------------------------------------------
//cookie処理
//------------------------------------------------------------------------------
function getCookie(name) {
	var result = null;
	var cookieName = name + '=';
	var allcookies = document.cookie;
	var position = allcookies.indexOf(cookieName);
	if (position !== -1) {
		var startIndex = position + cookieName.length;
		var endIndex = allcookies.indexOf(';', startIndex);
		if (endIndex === -1) {
			endIndex = allcookies.length;
		}
		result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
	}
	return result;
}

//------------------------------------------------------------------------------
// splash画面の非表示
//------------------------------------------------------------------------------
function splashDisappear() {
	var $trigger = $('.js-splashDisappear--trigger'),
		$target = $('.js-splashDisappear--target'),
		targetData = $trigger.data('target');

	// getCookie(Cookieの読み込み)
	function getCookieArray() {
		var arr = new Array();
		var _arr = document.cookie.split('; ');
		for (var i = _arr.length - 1; i >= 0; i--) {
			var data = _arr[i].split('=');
			//Cookieの名前をキーとして配列に追加する
			_arr[data[0]] = decodeURIComponent(data[1]);
		}
		return _arr;
	}

	// resultは、真偽値
	var _result = getCookieArray(),
		result = _result[targetData + '_splashFlag'];
	if (result) {
		$target.addClass('hide');
	} else {
		$target.removeClass('hide');
	}

	function setCookie(name, val) {
		document.cookie = name + '=' + val + '; path=/; expires=' + d3;
	}
	//クリックした時タブを非表示にし、cookieに格納
	$trigger.on('click', function() {
		// 時間取得 10年
		var d1 = new Date();
		var d2;
		var d3;
		d1.setTime(d1.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
		d2 = d1.toGMTString().split(' ');
		d3 = d2[0] + ' ' + d2[1] + '-' + d2[2] + '-' + d2[3] + ' ' + d2[4] + ' ' + d2[5];
		// バナーエリアの表示切り替え
		$target.toggleClass('hide');
		// cookieの値を取得し、取得したクッキー名の後ろに「_splashFlag」を付与
		document.cookie = targetData + '_splashFlag=true; path=/; expires=' + d3;
	});
}
